<template>
  <section class="container">
    <div class="flex w-full flex-col items-center rounded-3xl p-6 text-center max-md:pt-12 md:p-8 lg:p-16" :class="[backgroundColor]">
      <Badge :label="badgeText" background-color="bg-transparent" border-color="border-white" />
      <h2 class="mt-6 max-w-screen-lg text-4xl font-bold uppercase text-white md:text-5xl lg:text-6xl">{{ headingText }}</h2>
      <p class="mt-6 text-2xl font-light text-white lg:w-1/2">
        <slot />
      </p>
      <!-- CTA Slot -->
      <div v-if="$slots.cta" class="mt-8">
        <slot name="cta" />
      </div>

      <div v-if="$slots.cards" class="mt-12 grid w-full grid-cols-1 gap-6 md:mt-16 lg:grid-cols-3 lg:gap-8">
        <slot name="cards" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  const props = defineProps({
    backgroundColor: {
      type: String,
      default: 'bg-green-500'
    },
    badgeText: {
      type: String,
      default: 'How It Works'
    },
    headingText: {
      type: String,
      default: 'The PicUp Difference'
    }
  })
</script>
