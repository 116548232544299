<template>
  <div class="inline-flex items-center justify-center">
    <span
      class="font-display inline-flex items-center rounded-full border-2 px-3.5 py-1.5 text-sm font-semibold uppercase leading-none tracking-wider"
      :class="[backgroundColor, textColor, borderColor]">
      {{ label }}
    </span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    label: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'bg-green-500'
    },
    textColor: {
      type: String,
      default: 'text-white'
    },
    borderColor: {
      type: String,
      default: 'border-green-500'
    }
  })
</script>
