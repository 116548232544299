<template>
  <div class="flex w-full flex-1 flex-col gap-4 rounded-xl p-6 text-left text-2xl md:p-12" :class="[backgroundColor, textColor]">
    <Icon v-if="icon" :name="icon" class="text-6xl" :class="[iconColor]" />
    <h3 class="font-semibold">{{ heading }}</h3>
    <p class="font-light leading-normal">
      <slot />
    </p>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    heading: {
      type: String,
      default: 'How It Works'
    },
    backgroundColor: {
      type: String,
      default: 'bg-white'
    },
    textColor: {
      type: String,
      default: 'text-zinc-900'
    }
  })
</script>
