<template>
  <div class="flex w-1/2 flex-col items-center p-4 md:w-1/3 lg:w-1/3 xl:w-1/5">
    <slot />
    <div class="mt-5 flex flex-col">
      <div class="font-display text-2xl font-bold lg:text-3xl">{{ value }}</div>
      <p class="mt-1 text-xl font-light text-gray-500">{{ label }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  })
</script>
